<div class="page-not-found-container">
  <div class="number-container">
    <div class="number-box">
      <mat-icon
        class="number-img first"
        [svgIcon]="'number-four'"
      ></mat-icon>
    </div>
    <div class="number-box">
      <mat-icon
        class="number-img"
        [svgIcon]="'number-zero'"
      ></mat-icon>
    </div>
    <div class="number-box">
      <mat-icon
        class="number-img last"
        [svgIcon]="'number-four'"
      ></mat-icon>
    </div>
  </div>
  <div class="text-container">
    <h1 class="text-title">
      {{ 'LOCAL.SERVICE_PAGES.404.HEADER' | translate }}
    </h1>
    <p class="text-description">
      {{ 'LOCAL.SERVICE_PAGES.404.TEXT' | translate }}
    </p>
  </div>
  <a
    class="btn back-btn"
    langRouterLink
    [routerLink]="'/'"
    >{{ 'LOCAL.SERVICE_PAGES.404.BUTTON' | translate }}</a
  >
</div>
