import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';

import { NotFoundPageComponent } from './not-found-page.component';

@NgModule({
  imports: [TranslateModule, MatIconModule, CommonModule, RouterModule, LangRouterModule],
  declarations: [NotFoundPageComponent],
  exports: [NotFoundPageComponent],
})
export class NotFoundPageModule {}
