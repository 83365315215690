import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
// import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  response: Response;

  constructor(
    @Optional() @Inject(Response) response: any,
    private readonly router: Router,
  ) {
    this.response = response;
  }

  ngOnInit(): void {
    if (this.response) {
      this.response.status(404);
    } else {
      throw new Error('Page not found: ' + this.router.url);
    }
  }
}
