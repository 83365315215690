import { Injectable } from '@angular/core';
import { LotteryApiService } from '@dev-fast/backend-services';
import { ILottery, LotteryType } from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import moment from 'moment';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable, tap } from 'rxjs';

import { GetLotteryItems } from './lottery.actions';
import { LOTTERY_INITIAL_STATE, LotteryStateModel } from './lottery-state.model';

@State<LotteryStateModel>({
  name: 'lottery',
  defaults: LOTTERY_INITIAL_STATE,
})
@Injectable()
export class LotteryState {
  @Selector()
  static items({ items }: LotteryStateModel): ILottery[] {
    return items;
  }
  @Selector()
  static collectionToShow({ collectionToShow }: LotteryStateModel): ILottery[] {
    return collectionToShow;
  }

  constructor(
    private readonly apiService: LotteryApiService,
    private readonly store: Store,
    private readonly ws: WrappedSocket,
  ) {}

  @Action(GetLotteryItems)
  getItems({ patchState, getState }: StateContext<LotteryStateModel>): Observable<ILottery[]> {
    const { countToShow } = getState();
    return this.apiService
      .getLotteryState()
      .pipe(tap((response) => patchState({ items: response, collectionToShow: this._getCollectionToShow(response, countToShow) })));
  }
  private _getCollectionToShow(items: ILottery[], countToShow: number): ILottery[] {
    // последние незаконченые игры.
    let collection = items.filter((model) => moment(model.gameEndTimestamp).diff(moment()) > 0 && model.type !== LotteryType.FAST);

    // если есть минимум одна активная игра, то дополнить ее одной законченой (при условии ее наличия)
    if (collection.length !== 0 && collection.length < countToShow) {
      collection.push(...items.slice(0, countToShow - collection.length));
    } else if (collection.length === 0) {
      // если нет активных игр - показать неактивные
      collection = items.slice(0, countToShow);
    }
    // рандомные два розыгрыша
    return collection.sort((a: ILottery, b: ILottery) => {
      if (a.type === LotteryType.FREE) {
        return -1;
      }
      if (b.type === LotteryType.FREE) {
        return 1;
      }

      if (a.type === LotteryType.DEPOSIT) {
        return -1;
      }
      if (b.type === LotteryType.DEPOSIT) {
        return 1;
      }

      if (a.type === LotteryType.HOURLY) {
        return -1;
      }
      if (b.type === LotteryType.HOURLY) {
        return 1;
      }

      if (a.type === LotteryType.DAILY) {
        return -1;
      }
      if (b.type === LotteryType.DAILY) {
        return 1;
      }

      if (a.type === LotteryType.WEEKLY) {
        return -1;
      }
      if (b.type === LotteryType.WEEKLY) {
        return 1;
      }
      return 0;
    });
  }
}
