import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';

import { MotivatorWidgetModule } from './components/motivator-widget/motivator-widget.module';
import { RecentActivitiesComponent } from './recent-activities.component';
import { RecentActivitiesService } from './recent-activities.service';
import { RECENT_ACTIVITIES_ENGINE } from './symbols';

@NgModule({
  declarations: [RecentActivitiesComponent],
  imports: [CommonModule, MotivatorWidgetModule, PortalModule],
  exports: [RecentActivitiesComponent],
})
/** Shared activities */
export class RecentActivitiesWidgetsModule {
  static forRoot(engine?: TypeProvider): ModuleWithProviders<RecentActivitiesWidgetsModule> {
    return {
      ngModule: RecentActivitiesWidgetsModule,
      providers: [
        {
          provide: RECENT_ACTIVITIES_ENGINE,
          useClass: engine ? engine : RecentActivitiesService,
        },
      ],
    };
  }

  static forChild(engine?: TypeProvider): ModuleWithProviders<RecentActivitiesWidgetsModule> {
    return {
      ngModule: RecentActivitiesWidgetsModule,
      providers: [
        {
          provide: RECENT_ACTIVITIES_ENGINE,
          useClass: engine ? engine : RecentActivitiesService,
        },
      ],
    };
  }
}
