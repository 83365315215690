import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IBaseWidgetBlock, ICaseRevisionItem } from '@dev-fast/types';

import { changeAnimation } from './motivator-widget.animation';

@Component({
  selector: 'app-motivator-widget',
  templateUrl: './motivator-widget.component.html',
  styleUrls: ['./motivator-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

  animations: [changeAnimation],
})
export class MotivatorWidgetComponent {
  @Input() public data: IBaseWidgetBlock | undefined;
  @Output() public navigateToItem: EventEmitter<{ skin: string; shortName: string }>;
  // public player: IActivities['luckyPlayer'][number];
  constructor() {
    this.navigateToItem = new EventEmitter(false);
  }
  // public ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.luckyPlayer.currentValue?.length) {
  //     const [first] = changes.luckyPlayer.currentValue;
  //     this.player = first;
  //   }
  // }
  public navigateToCaseItem(itemRevisions: ICaseRevisionItem): void {
    const params = {
      skin: itemRevisions.inventoryItem.baseItem.skin,
      shortName: itemRevisions.inventoryItem.baseItem.shortName,
    };
    this.navigateToItem.emit(params);
  }
}
