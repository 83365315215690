import { Component, Input } from '@angular/core';
import { Widgets } from '@dev-fast/types';

@Component({
  selector: 'app-motivator-widget-mock',
  templateUrl: './motivator-widget.component.mock.html',
  styleUrls: ['../motivator-widget.component.scss', './motivator-widget.component.mock.scss'],
})
export class MotivatorWidgetMockComponent {
  @Input() public widget!: Widgets;
}
