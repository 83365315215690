import { ILottery } from '@dev-fast/types';

export class GetLotteryItems {
  static readonly type = '[Lottery] Get items';
}
export class ParticipateOnFree {
  static readonly type = '[Lottery] Participate On Free';
}
export class ItemUpdated {
  static readonly type = '[Lottery] Item Updated';
  constructor(public readonly id: number, public readonly item: ILottery) {}
}
