@if (data) {
  <div
    class="widget"
    [style.height]="data.height || '19.5rem'"
  >
    <h4 class="widget-header">
      {{ data.header | translate }}
    </h4>
    @if (data.replayDrop) {
      <div class="widget-content drop-card">
        <app-ui-drop-card
          [drop]="data.replayDrop"
          [className]="'bestdrop-widget'"
          (clickOnCard)="navigateToCaseItem($event)"
          [isFullView]="true"
        ></app-ui-drop-card>
      </div>
    } @else {
      <div class="widget-content">
        <div class="widget-avatar-container">
          <app-ui-user-avatar
            [url]="data.avaLink"
            [userId]="data.id"
            [@changeAnimation]="data.id"
          ></app-ui-user-avatar>
        </div>
        <div class="widget-player-name">
          <div class="widget-player-name-wrapper">{{ data.name }}</div>
        </div>
      </div>
    }
    @if (data.payload; as payload) {
      <div class="widget-footer">
        @for (item of payload; track item) {
          <div class="widget-footer--card-info --plain-text">
            <div class="widget-footer--card-info-title">{{ item.label | translate }}:</div>
            <div class="widget-footer--card-info-value">
              @switch (item.valueType) {
                @case ('currency') {
                  {{ item.value | appCurrency }}
                }
                @case ('percent') {
                  {{ item.value }}%
                }
                @case ('user') {
                  <app-ui-user-avatar
                    [url]="data.avaLink"
                    [userId]="data.id"
                    [@changeAnimation]="data.id"
                  ></app-ui-user-avatar>
                  <span class="widget-footer--card-info-value--user-nickname"> {{ data.name }}</span>
                }
                @default {
                  {{ item.value }}
                }
              }
            </div>
          </div>
        }
        <!-- <div class="widget-footer--card-info --action-btn">
        <app-ui-user-avatar
          [url]="data.avaLink"
          [userId]="data.id"
          [@changeAnimation]="data.id"
        ></app-ui-user-avatar>
        <div>x170</div>
      </div> -->
      </div>
    }
  </div>
}
